<template>
    <v-container>

      <v-row>
        <v-col cols="12" md="5" sm="5" xm="12" >
          <v-toolbar dense color="transparent" flat>
            <h4>Subir fotos del pago pedido {{numdoc}}</h4>
            <v-spacer></v-spacer>

            <v-btn color="info" small @click="regresar"><v-icon>reply</v-icon></v-btn>
          </v-toolbar>

          <v-divider></v-divider>
          <!-- <v-btn small v-if="pagar1btn" dark color="green" @click="mostrarpagar1" class="mx-2">Subir Anticipo</v-btn>
          <v-btn small v-if="pagar2btn" dark color="red" @click="mostrarpagar2" class="mx-2">Subir Pago</v-btn>
           -->

           <!-- VISTAS DE PAGAR -->
          <pagar1 v-if="pagar1btn"></pagar1>
          <pagar2 v-if="pagar2btn"></pagar2>

        </v-col>

        <v-col cols="12" md="7" sm="7" xm="12" v-if="docpost == ''">
          <v-card elevation=20>
            <documento></documento>
          </v-card>
        </v-col>

        
        <v-col cols="12" md="7" sm="7" xm="12" v-if="docpost != ''">
          <v-card elevation=20>
            <documentov2></documentov2>
          </v-card>

        </v-col>

      </v-row>
    </v-container>
</template>

<script>

import documento from '@/views/cotizaciones/Documento.vue'
import documentov2 from '@/views/cotizaciones/DocumentoV2.vue'

import pagar1 from '@/views/seguimiento/opciones/pagar1.vue'
import pagar2 from '@/views/seguimiento/opciones/pagar2.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    pagar1,
    pagar2,
    documento,
    documentov2
  },

  computed: {
    ...mapGetters("documento", ["getDocumento"]),
  },

  created () {
    this.docpost = this.getDocumento.docpost
    console.log("docpost", this.docpost)
    // console.log("params", this.$route.params.info)
    const statuspedido = this.$route.params.info.statusped
    console.log("status pedido", statuspedido)

    this.numdoc = this.$route.params.info.numdoc
    if (statuspedido == '3' || statuspedido == '4' || statuspedido == '5') {
      console.log(statuspedido)
      this.pagar2btn = true
      this.mostrarpagar2 = true
      this.mostrarpagar1 = false
    }

    if (statuspedido == '1' || statuspedido == '0' || statuspedido == '2') {
      console.log(statuspedido)
      this.pagar1btn = true
    }
  },
  data: () => ({
    verpagar1: true,
    verpagar2: false,
    numdoc: '',
    iddocum: 0,
    pagar1btn: false,
    pagar2btn: false,
    docpost:''
  }),
  
  methods: {

    mostrarpagar1 () {
      this.verpagar1 = true
      this.verpagar2 = false
    },
    mostrarpagar2 () {
      this.verpagar2 = true
      this.verpagar1 = false
    },

    regresar () {
      this.$router.back()
    }
  }

}
</script>
