<template>
  <v-container v-if="mostrardoc">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-col cols="12" class="ma-0 pa-0">
              <v-card class="elevation-0 transparent" align="left">
                <v-row>
                  <!-- LOGO -->
                  <v-col cols="12" md="3" sm="3" xs="12" >
                    <v-img contain :src="logo" alt="alt" ></v-img>
                  </v-col>

                  <v-col cols="12" md="8" sm="8" xs="12">
                    <v-card-text align="center" class="ma-0 pa-0">
                      <h3 class="red--text">PEDIDO SURTIDO {{getDocumento.numdoc }}</h3>
                      <h4 class="font-weight-black">
                        Fecha: {{ Docum.Fecha }}
                      </h4>
                      Surtido {{docpost}}
                    </v-card-text>
                  </v-col>

                  <!-- <v-col cols="4" md="4" sm="4" xs="12">
                    <v-card-actions>
                  
                      <v-btn small color="secondary" value="Print this page">
                        <v-icon color="white" @click="mostrarComprobante"
                          >print</v-icon
                        >
                      </v-btn>

                      <v-btn small color="info" @click="regresar">
                        <v-icon color="white">reply</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-col> -->
                </v-row>
              </v-card>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- DATOS DE LA EMPRESA -->
      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card>
          <v-card-text>
            <div class="text-xs-left blue--text font-weight-black">
              {{ getCIA.nomcia }}
            </div>
            <div class="text-xs-left red--text">
              <strong>{{ getCIA.rfccia }}</strong>
            </div>
            <div class="text-xs-left">
              {{ getCIA.calle + " " + getCIA.numext + " " + getCIA.colonia }}
            </div>
            <div class="text-xs-left">
              {{ getCIA.ciudad + ", " + getCIA.estado + " CP: " + getCIA.cp }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- DATOS DEL DOCUMENTO Y DEL CLIENTE-->
      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card>
          <v-card-text>
            <div class="text-xs-left font-weight-black">
              {{ Clientes.Nomcli }}
            </div>
            <div class="text-xs-left red--text font-weight-black">
              RFC {{ Clientes.Rfc }}
            </div>
            <div class="text-xs-left">
              {{
                Clientes.Calle + " " + Clientes.Numext + " " + Clientes.Colonia
              }}
            </div>
            <div>
              {{ Clientes.Ciudad + " " + Clientes.Estado }} CP {{ Clientes.Cp }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12" class="ma-0 pa-0">
        <v-card class="elevation-0 ma-0 pa-0">
          <v-card-text class="ma-0 pa-0">
            <v-radio-group
              v-model="radios"
              :mandatory="false"
              row
              dense
              readonly
            >
              <v-radio
                label="Recoger en tienda"
                value="1"
                color="primary"
              ></v-radio>
              <v-radio
                label="Envío a domicilio"
                value="2"
                color="primary"
              ></v-radio>
            </v-radio-group>

            <!-- {{getDirecciones}} -->
            <!-- @change="seldire(` ${select.idweb}`)" -->
            <v-select
              v-if="radios == 2"
              v-model="select"
              :hint="`  ${select.Calle} ${select.Numext} ${select.colonia} ${select.Ciudad}
                      ${select.Estado} CP ${select.Cp} Tel. ${select.telefono} `"
              :items="getDirecciones"
              item-text="nombre"
              item-value="idweb"
              label="Selecionar Dirección de envio"
              prepend-icon="house"
              persistent-hint
              return-object
              readonly
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12" class="ma-0 pa-0">
        <v-card class="ma-1 pa-1">
          <v-row>
            <v-col xs6 class="ma-0 pa-0">
              <v-card class="transparent elevation-0 ma-0 py-0" width="100%">
                <v-card-text class="ma-1 px-1">
                  <div align="right">
                    <h3><strong>Importe:</strong></h3>
                  </div>
                  <div align="right">
                    <h3><strong>Descuento:</strong></h3>
                  </div>
                  <div align="right">
                    <h3><strong>Subtotal:</strong></h3>
                  </div>
                  <div align="right">
                    <h3><strong>Iva:</strong></h3>
                  </div>
                  <div align="right">
                    <h3><strong>Total:</strong></h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs6 class="ma-1 pa-1">
              <v-card class="transparent elevation-0" width="100%">
                <v-card-text align="right" class="mx-1 px-4">
                  <div>
                    <h3>
                      <strong>{{ Importe }}</strong>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      <strong>{{ Descuento }}</strong>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      <strong>{{ Subtotal }}</strong>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      <strong>{{ Iva }}</strong>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      <strong>{{ Total }}</strong>
                    </h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- TABLA DE PARTIDAS  -->
      <v-col cols="12" xs="12" class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="MovinArray"
            :items-per-page="longitud"
            fixed-header
            height="600"
            class="elevation-1 pa-0 text-xs-center"
            hide-default-footer
          >
            <template v-slot:no-data>
              <v-btn color="primary" @click="init">Reset</v-btn>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="comprobanteModal" max-width="1000">
      <Impresion />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import autoTable from "jspdf-autotable";
import Impresion from "@/views/pedidos/Impresion.vue";
import tempMovin from "@/mixins/tempMovin.js";
var accounting = require("accounting");
var base64 = require("base-64");

export default {
  mixins: [tempMovin],
  components: {
    Impresion,
  },

  data() {
    return {
      mostrardoc:false,
      select: {
        nombre: "",
        idweb: "",
        encargado: "",
        Calle: "",
        Numext: "",
        colonia: "",
        Ciudad: "",
        Estado: "",
        Cp: "",
        telefono: "",
        numclisuc: "",
      },

      logo: "",

      Importe: 0,
      Descuento: 0,
      Subtotal: 0,
      Iva: 0,
      Total: 0,

      MovinArray: [],
      tipodoc: "",
      loading: true,
      Clientes: {},
      Movim: [],
      Docum: "",
      radios: "1",
      dialog: false,
      total: "",
      direccion: "",
      headers: [
        // { text: 'ID'		   		,align: 'center', value: 'Numpar'      ,sortable: false },
        { text: "Clave", align: "left", value: "Numart", sortable: false },
        {
          text: "Descripción",
          align: "left",
          value: "Descrip",
          sortable: false,
        },
        { text: "Cantidad", align: "center", value: "Cant", sortable: false },
        { text: "Unidad", align: "center", value: "Unidad", sortable: false },
        { text: "Precio", align: "right", value: "Precio", sortable: false },
        {
          text: "Descuento",
          align: "right",
          value: "Descuento",
          sortable: false,
        },
        // { text: 'Impuesto'	   ,align: 'right',  value: 'Impuesto1'   ,sortable: false },
        { text: "Importe", align: "right", value: "Importe", sortable: false },
        {
          text: "Subtotal",
          align: "right",
          value: "Subtotal",
          sortable: false,
        },
        {
          text: "Precio Neto",
          align: "right",
          value: "Total",
          sortable: false,
        },
      ],
      comprobanteModal: 0,
      tipo: "",
      longitud: 10,
    };
  },

  created() {
    console.log("documento v2")
    this.docpost = this.getDocumento.docpost
    this.numdoc = this.getDocumento.numdoc
    this.$http.get("api/v1/logos.list").then(
      (response) => {
        this.logo = response.body.Logourl;
      },
      (error) => {
        console.log(error);
      }
    );

    let tipodoc = "";

    // console.log("params", this.$route.params)

    if (this.$route.params.info != undefined) {
      // si no manda parametros rechaazar
      // console.log("decode", this.$route.params.info)
      tipodoc = this.$route.params.info.iddocum;

      // console.log("iddocum",tipodoc)
      // tipodoc = this.getDocumento.Iddocum
    } else {
      tipodoc = this.getDocumento.Iddocum;
    }

    // console.log("Iddocum", tipodoc)

    if (tipodoc) {
      this.init(tipodoc);
      // console.log(this.$route.params)
      //MANDAR A VUEX
      // console.log(this.getDocumento.Tipodoc)

      // this.addDocumento({ iddocum: tipodoc });
    }
  },

  computed: {
    ...mapGetters("carrito", ["getCarrito", "getTC"]),
    ...mapGetters("documento", ["getDocumento"]),
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getClientes",
      "getCliente",
      "getCIA",
      "getModo",
    ]),
    ...mapGetters("direccion", ["getDirecciones", "getDireccion"]),
  },

  methods: {
    ...mapActions("documento", ["addTotales", "addDocumento"]),
    ...mapActions("direccion", ["traerDirecciones"]),

    // COMPROBANTE DE PAGO

    mostrarComprobante() {
      this.$router.push({ name: "impresion" }).catch((err) => {
        console.log(err);
      });
    },

    regresar() {
      // this.$router.push({name:'consultadoc'}).catch(err => {console.log(err)})
      this.$router.back();
    },

    init(tipodoc) {
      //Consultar Documento.
      this.loading = true;

      // console.log(this.getdatosUsuario.numcli)
      this.traerDirecciones(this.getdatosUsuario.numcli).then((response) => {
        // console.log("direcciones carrito", response)
      });

      this.getDirecciones;


      let cTipodoc = this.docpost.substring(0,2)
      let cNumdoc = this.docpost.substring(2,12)

      let payload = {
        "tipodoc": cTipodoc,
        "numdoc" : cNumdoc
      }
      console.log("payload", payload)
      this.$http
        .post("api/v1/order.info",payload)
        .then((response) => {
          console.log("response", response)
          if (response.status  === 500) {
            this.mostrardoc = false
            return
          }

          this.mostrardoc = true
          console.log("api order",response)

          // this.addDocumento(response.data);
          
          // console.log(this.getDocumento)
          response.body.Importe = accounting.unformat(response.body.Importe);
          response.body.Impuesto1 = accounting.unformat(
            response.body.Impuesto1
          );
          response.body.Descuento = accounting.unformat(
            response.body.Descuento
          );

          this.total = accounting.formatNumber(
            parseFloat(response.body.Importe) -
              parseFloat(response.body.Descuento) +
              parseFloat(response.body.Impuesto1),
            2
          );

          response.body.Importe = accounting.formatNumber(
            response.body.Importe,
            2
          );
          response.body.Impuesto1 = accounting.formatNumber(
            response.body.Impuesto1,
            2
          );
          response.body.Descuento = accounting.formatNumber(
            response.body.Descuento,
            2
          );

          this.Docum = response.body;
          //
          if (response.body.Clientes != null) {
            this.Clientes = response.body.Clientes[0];
          }
          this.Movim = response.body.Movim;

          this.crearArreglo();
          // Cargar direnvio
          // console.log("DOCUM",this.Docum)

          if (this.Docum.Tipodoc == " P") {
            this.tipo = "Pedido";
          } else {
            this.tipo = "";
          }

          if (this.Docum.Direnvio.trim() != "" && this.Docum.Numclisuc != "") {
            console.log("getnumclisuc", this.Docum.numclisuc);
            this.radios = "2";
            this.Docum.numclisuc;
            var cNumclisuc = this.Docum.numclisuc.trim();

            this.$http
              .get("auth/api/v1/getnumclisuc/" + cNumclisuc)
              .then((response) => {
                console.log("numclisuc estado", response);
                this.idweb = response.data.idweb;
                this.select = response.data;
              });
          } else {
            this.radios = "1";
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    crearArreglo() {
      this.Movim.forEach((element) => {
        element.Precio = accounting.unformat(element.Precio);
        element.Impuesto1 = accounting.unformat(element.Impuesto1);
        element.Impuesto2 = accounting.unformat(element.Impuesto2);
        element.Importe = accounting.formatNumber(
          element.Cant * accounting.unformat(element.Precio),
          2
        );
        element.Iva = 0;
        element.Total = 0;
      });

      this.MovinArray = this.Movim;
      this.longitud = this.MovinArray.length;

      // console.log("crearArreglo")
      this.formarMovim(this.Movim);
    },

    //MANDAR IMPRIMIR
    order() {
      var totales = {
        importe: this.Importe,
        descuento: this.Descuento,
        subtotal: this.Subtotal,
        iva: this.Iva,
        total: this.Total,
      };

      //Vuex
      this.addTotales(totales);
      this.$router
        .push({ name: "tipopago", params: { tipo: "1" } })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
